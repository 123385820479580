import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  limit,
  getDoc,
  orderBy,
} from "firebase/firestore";
import Modal from "react-bootstrap/Modal";
import { useAlert } from "react-alert";
import * as XLSX from "xlsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import "./History.css";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import "moment/locale/fr";
import "moment/locale/nl";

import { db } from "../../../firebase";

import itemSoldImg from "../../../images/itemSold.svg";
import totalRevenueImg from "../../../images/totalRevenue.svg";
import profile1 from "../../../images/profile1.png";

import { ReactComponent as All } from "../../../images/All.svg";
import { ReactComponent as Card } from "../../../images/Card.svg";
import { ReactComponent as Cash } from "../../../images/Cash.svg";
import { ReactComponent as Qr } from "../../../images/Qr.svg";
import { ReactComponent as Refund } from "../../../images/Refund.svg";

import { Icons } from "../../../images/icons";
import { Loader2 } from "../../../helper/loader";

let now = new Date();
let initialStartDate = new Date(now); // Start with the current date
let initialEndDate = new Date(now); // Start with the current date

// Set initialStartDate to yesterday
initialStartDate.setDate(initialStartDate.getDate() - 1);

// Set initialEndDate to now
initialEndDate.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());

// Optional: If you want to set the time of initialStartDate to the beginning of the day
initialStartDate.setHours(0, 0, 0, 0);

const exportToXlsx = (orderData, startDate, endDate) => {
  const formattedData = orderData.map((order) => ({
    payment_type: order.paymentType,
    timestamp: order.createdAt
      ? moment(parseInt(order.createdAt)).format("DD MMMM YYYY HH:mm:ss")
      : "N/A",
    cashier: order.createdBy === "cashless" ? "Fast-Pass" : order.creatorName,
    customer: order.createdBy === "cashless" ? order.creatorName : null,
    amount: order.totalAmount.toFixed(2),
    products: order.products.map(product => `${product.title} (${product.quantity} x ${product.price} €)`).join(", ") // New column for product details
  }));

  var startDate1 = startDate.getTime();
  var endDate1 = endDate.getTime();

  var start = new Date(startDate1);
  start.setHours(start.getHours());

  var end = new Date(endDate1);
  end.setHours(end.getHours() + 24);

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const formattedStartDate = formatDate(start);
  const formattedEndDate = formatDate(end);

  const worksheet = XLSX.utils.json_to_sheet(formattedData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Orders");

  const columnWidths = [
    { wch: 20 }, // Payment Type
    { wch: 25 }, // Timestamp
    { wch: 20 }, // Cashier
    { wch: 20 }, // Customer
    { wch: 15 }, // Amount
    { wch: 50 }, // Products
  ];
  worksheet["!cols"] = columnWidths;

  const headers = [
    "Payment Type",
    "Timestamp",
    "Cashier",
    "Customer",
    "Amount",
    "Products", // New header for products
  ];
  headers.forEach((header, index) => {
    const cell = worksheet[XLSX.utils.encode_cell({ r: 0, c: index })];
    if (cell) {
      cell.s = {
        font: {
          bold: true,
        },
      };
    }
  });

  XLSX.writeFile(
    workbook,
    `Orders_${formattedStartDate}_${formattedEndDate}.xlsx`
  );
};


function History({ organizations, orgId, paymentMethod, setPaymentMethod }) {
  const navigate = useNavigate();
  const alert = useAlert();
  const { t, i18n } = useTranslation("history");

  moment.locale(i18n.language);

  const [productData, setProductData] = useState([]);
  const [productDataInitial, setproductDataInitial] = useState([]);
  const [orderData, setOrderData] = useState([]);
  const [orderDataInitial, setOrderDataInitial] = useState([]);
  const [itemSold, setItemSold] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [softRevenue, setsoftRevenue] = useState(0);
  const [hotDrinkRevenue, setHotDrinkRevenue] = useState(0);
  const [beerRevenue, setBeerRevenue] = useState(0);
  const [cocktailRevenue, setCocktailRevenue] = useState(0);
  const [foodRevenue, setFoodRevenue] = useState(0);
  const [wineRevenue, setWineRevenue] = useState(0);
  const [otherRevenue, setOtherRevenue] = useState(0);
  const [loader, setLoader] = useState(false);
  const [orderSelected, setOrderSelected] = useState({});
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [members, setMembers] = useState([]);
  const [selectedCashier, setSelectedCashier] = useState();

  const [filter, setFilter] = useState("All");
  const [submitTimeCount, setSubmitTimeCount] = useState(0);

  const [show, setShow] = useState(false);
  const [reloading, setReloading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getOrgProduct = async () => {
    //setLoader(true);
    let records = [];
    const chunkSize = 10; // Firestore limit for 'in' query
    const orgIdChunks = [];

    // Split orgIds into chunks
    for (let i = 0; i < orgId.length; i += chunkSize) {
      orgIdChunks.push(orgId.slice(i, i + chunkSize));
    }

    for (const chunk of orgIdChunks) {
      const q = query(collection(db, "products"), where("orgId", "in", chunk));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const productData = doc.data();

        // Check if a product with the same title and price already exists
        const exists = records.some(
          (record) =>
            record.title === productData.title &&
            record.price === productData.price &&
            record.category === productData.category
        );

        if (!exists) {
          records.push(productData); // Only add if title and price are unique
        }
      });
    }

    setProductData(records);
    setproductDataInitial(records);
    // setLoader(false);
  };

  async function getMembersFromDb(foundOrg) {
    if (!foundOrg) {
      alert.show(t("alert.noOrgSelected"), { type: "error" });
      return;
    }

    let membersArrayTemp = [];

    const rles = ["adminUid", "cashierUid", "stewardUid"];
    const roleLabels = {
      adminUid: "admin",
      cashierUid: "cashier",
      stewardUid: "steward",
    };

    try {
      for (const role of rles) {
        for (const uid of foundOrg[role]) {
          const userDoc = await getDoc(doc(db, "Store", uid));
          if (userDoc.exists()) {
            membersArrayTemp.push({
              ...userDoc.data(),
              roleInOrg: roleLabels[role],
            });
          }
        }
      }
      console.log(membersArrayTemp);
      setMembers(membersArrayTemp);
    } catch (error) {
      console.error(t("error.failedFetchMembers"), error);
      alert.show(t("error.failedLoadMembers"), { type: "error" });
    }
  }

  const getOrdersData = async () => {
    setLoader(true);
    let records = [];

    let q;
    if (startDate && endDate && startDate < endDate) {
      console.log("Range is defined");
      const startTimestamp = startDate.getTime().toString(); // Convert to string
      const endTimestamp = endDate.getTime().toString(); // Convert to string

      console.log(startTimestamp);
      console.log(endTimestamp);
      console.log(orgId);

      // Check if selectedCashier is defined and not empty
      if (selectedCashier) {
        q = query(
          collection(db, "Orders"),
          where("orgId", "==", orgId[0]),
          where("createdAt", ">=", startTimestamp),
          where("createdAt", "<=", endTimestamp),
          where("createdBy", "==", selectedCashier), // Filter by selectedCashier
          orderBy("createdAt", "desc"), // Order by createdAt descending
          limit(250)
        );
      } else {
        q = query(
          collection(db, "Orders"),
          where("orgId", "==", orgId[0]),
          where("createdAt", ">=", startTimestamp), // Now using string timestamps
          where("createdAt", "<=", endTimestamp), // Now using string timestamps
          orderBy("createdAt", "desc"), // Order by createdAt descending
          limit(250) // Limit to the first 250 documents
        );
      }
    } else {
      if (selectedCashier) {
        q = query(
          collection(db, "Orders"),
          where("orgId", "in", orgId),
          where("createdBy", "==", selectedCashier), // Filter by selectedCashier
          orderBy("createdAt", "desc"), // Order by createdAt descending
          limit(250)
        );
      } else {
        q = query(
          collection(db, "Orders"),
          where("orgId", "in", orgId),
          orderBy("createdAt", "desc"), // Order by createdAt descending
          limit(250) // Limit to the first 250 documents
        );
      }
    }

    try {
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        records.push(doc.data());
      });
    } catch (error) {
      console.log("Error fetching orders:", error);
    }

    setOrderData(records);
    findPDetail(records);
    setLoader(false);
  };

  const findPDetail = (orderData1) => {
    let findOrder = [];
    let findQantity = 0;
    let findRevenue = 0;

    let findSoftQuantity = 0;
    let findBeerQuantity = 0;
    let findCocktailQuantity = 0;
    let findFoodQuantity = 0;
    let findWineQuantity = 0;
    let findHotDrinksQuantity = 0;
    let findOtherQuantity = 0;
    var order = orderData1.forEach((element) => {
      for (let i = 0; i < element.products.length; i++) {
        findQantity = findQantity + element.products[i].quantity;
        if (element.products[i].category == "Softs") {
          findSoftQuantity =
            findSoftQuantity +
            element.products[i].quantity * element.products[i].price;
        }
        if (element.products[i].category == "Beers") {
          findBeerQuantity =
            findBeerQuantity +
            element.products[i].quantity * element.products[i].price;
        }
        if (element.products[i].category == "Cocktails") {
          findCocktailQuantity =
            findCocktailQuantity +
            element.products[i].quantity * element.products[i].price;
        }
        if (element.products[i].category == "Food") {
          findFoodQuantity =
            findFoodQuantity +
            element.products[i].quantity * element.products[i].price;
        }
        if (element.products[i].category == "Wine") {
          findWineQuantity =
            findWineQuantity +
            element.products[i].quantity * element.products[i].price;
        }
        if (element.products[i].category == "Hot drinks") {
          findHotDrinksQuantity =
            findHotDrinksQuantity +
            element.products[i].quantity * element.products[i].price;
        }
        if (element.products[i].category == "Other") {
          findOtherQuantity =
            findOtherQuantity +
            element.products[i].quantity * element.products[i].price;
        }
      }
      findRevenue = findRevenue + element.totalAmount;
    });

    // revenue for graph
    setsoftRevenue(findSoftQuantity);
    setBeerRevenue(findBeerQuantity);
    setCocktailRevenue(findCocktailQuantity);
    setFoodRevenue(findFoodQuantity);
    setWineRevenue(findWineQuantity);
    setHotDrinkRevenue(findHotDrinksQuantity);
    setOtherRevenue(findOtherQuantity);
    // revenue for graph

    setTotalRevenue(findRevenue);
    setItemSold(findQantity);
  };

  useEffect(() => {
    if (orgId) {
      setSelectedCashier();
      getOrdersData();
    }
  }, [orgId, reloading]);

  useEffect(() => {
    if (orgId[0]) {
      const findOrg = organizations.find((org) => org.id === orgId[0]);
      getMembersFromDb(findOrg);
    }
  }, [orgId, organizations]);

  const tableFilter = () => {
    if (filter.toLowerCase() === "all") {
      setProductData(productDataInitial);
    } else {
      let tempData = productDataInitial.filter(
        (p) => p.category.toLowerCase() === filter.toLowerCase()
      );
      setProductData(tempData);
    }
  };

  // useEffect(() => {
  //   tableFilter();
  // }, [filter]);

  const getDataFromTimstamp = async () => {
    try {
      setLoader(true);
      var startDate1 = startDate.getTime();
      var endDate1 = endDate.getTime();

      var start = new Date(startDate1);
      start.setHours(start.getHours());

      var end = new Date(endDate1);
      end.setHours(end.getHours() + 24);

      let records = [];
      const chunkSize = 10; // Firestore limit for 'in' query
      const orgIdChunks = [];

      // Split orgIds into chunks
      for (let i = 0; i < orgId.length; i += chunkSize) {
        orgIdChunks.push(orgId.slice(i, i + chunkSize));
      }

      for (const chunk of orgIdChunks) {
        const q = query(
          collection(db, "Revenues"),
          where("orgId", "in", chunk),
          where("timestamp", ">", start.getTime().toString()),
          where("timestamp", "<", end.getTime().toString())
        );

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
          records.push(doc.data());
        });
      }

      setOrderData(records);
      setOrderDataInitial(records);
      findPDetail(records);
      setPaymentMethod("");
    } catch (error) {
      console.error("Error fetching data from timestamp:", error);
      alert("An error occurred while fetching data. Please try again later.");
    } finally {
      setLoader(false);
    }
  };

  // useEffect(() => {
  //   if (submitTimeCount !== 0) {
  //     getDataFromTimstamp();
  //   }
  //   setSubmitTimeCount(submitTimeCount + 1);
  // }, [dateClickHandle]);

  const getDataByPaymentMethod = () => {
    //setLoader(true);

    let endData = [];
    if (paymentMethod === "card") {
      endData = orderDataInitial.filter((d) => d.paymentType === "card");
    } else if (paymentMethod === "cash") {
      endData = orderDataInitial.filter((d) => d.paymentType === "cash");
    } else {
      endData = orderDataInitial;
    }
    setOrderData(endData);
    findPDetail(endData);
    //setLoader(false);
  };

  useEffect(() => {
    if (paymentMethod) {
      findPDetail(orderData);
    }
  }, [paymentMethod]);

  const handleClickLiquidity = (item) => {
    setOrderSelected(item);
    handleShow();
  };

  return (
    <>
      <div className="container-fluid dashboardMain">
        <div className="row mx-0">
          <div className="col-xxl-8 col-xl-9">
            <div className="row row1">
              <div className="col-lg-5 order-2 order-md-1">
                <div className="themeCard itemCard">
                  <div className="itemImg">
                    <img className="img-fluid" src={itemSoldImg} alt="" />
                  </div>
                  <div className="content">
                    <h3>{itemSold}</h3>
                    <p>{t("products_sold")}</p>
                  </div>
                </div>
                <div className="themeCard itemCard mb-0">
                  <div className="itemImg">
                    <img className="img-fluid" src={totalRevenueImg} alt="" />
                  </div>
                  <div className="content">
                    <h3>
                      {totalRevenue > 0
                        ? totalRevenue.toFixed(2)
                        : totalRevenue}{" "}
                      €
                    </h3>
                    <p>{t("total_revenue")}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 order-1 order-md-2">
                <div className="themeCard col2">
                  <h3>{t("progress")}</h3>
                  <div className="chartRow">
                    <div className="chartMain">
                      <RevenueChart
                        softRevenue={softRevenue}
                        beerRevenue={beerRevenue}
                        cocktailRevenue={cocktailRevenue}
                        hotDrinkRevenue={hotDrinkRevenue}
                        foodRevenue={foodRevenue}
                        wineRevenue={wineRevenue}
                        otherRevenue={otherRevenue}
                      />
                    </div>
                    <div className="content">
                      <div className="colorRow">
                        <div className="circle blue"></div>
                        {beerRevenue
                          ? ((beerRevenue / totalRevenue) * 100).toFixed(2)
                          : 0}{" "}
                        % {t("beers")}
                      </div>
                      <div className="colorRow">
                        <div className="circle pink"></div>
                        {softRevenue
                          ? ((softRevenue / totalRevenue) * 100).toFixed(2)
                          : 0}{" "}
                        % {t("softs")}
                      </div>
                      <div className="colorRow">
                        <div className="circle green"></div>
                        {foodRevenue
                          ? ((foodRevenue / totalRevenue) * 100).toFixed(2)
                          : 0}{" "}
                        % {t("food")}
                      </div>
                      <div className="colorRow">
                        <div className="circle yellow"></div>
                        {cocktailRevenue
                          ? ((cocktailRevenue / totalRevenue) * 100).toFixed(2)
                          : 0}{" "}
                        % {t("cocktails")}
                      </div>
                      <div className="colorRow">
                        <div className="circle orange"></div>
                        {wineRevenue
                          ? ((wineRevenue / totalRevenue) * 100).toFixed(2)
                          : 0}{" "}
                        % {t("wine")}
                      </div>
                      <div className="colorRow">
                        <div className="circle red"></div>
                        {hotDrinkRevenue
                          ? ((hotDrinkRevenue / totalRevenue) * 100).toFixed(2)
                          : 0}{" "}
                        % {t("hot_drinks")}
                      </div>
                      <div className="colorRow">
                        <div className="circle purple"></div>
                        {otherRevenue
                          ? ((otherRevenue / totalRevenue) * 100).toFixed(2)
                          : 0}{" "}
                        % {t("others")}
                      </div>
                      <div className="total dn-sm">
                        {t("total_revenue")}: €{" "}
                        {totalRevenue > 0
                          ? totalRevenue.toFixed(2)
                          : totalRevenue}
                      </div>
                    </div>
                  </div>
                  <div className="total dn-lg ">
                    {t("total_revenue")}: €{" "}
                    {totalRevenue > 0 ? totalRevenue.toFixed(2) : totalRevenue}
                  </div>
                </div>
              </div>
            </div>

            <Table
              productData={productData}
              orderData={orderData}
              totalRevenue={totalRevenue}
              tableFilter={tableFilter}
              setFilter={setFilter}
              filter={filter}
              startDate={startDate}
              endDate={endDate}
              handleClickLiquidity={(order) => {
                handleClickLiquidity(order);
              }}
            />
          </div>
          <div className="col-xxl-4 col-xl-3">
            <div className="amountMainCard themeCard">
              <h5 className="subTitle">{t("filters")}</h5>
              <div className="text">{t("max_250_orders")}</div>

              <div className="d-flex justify-content-between mb-3">
                <div className="pickerDiv me-2">
                  <label className="white-text">{t("start_date")}</label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="Pp"
                    placeholderText={t("select_start_date")}
                    className="form-control"
                    popperClassName="datepicker-popper"
                    calendarClassName="datepicker-calendar"
                  />
                </div>
                <div className="pickerDiv">
                  <label className="white-text">{t("end_date")}</label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="Pp"
                    placeholderText={t("select_end_date")}
                    className="form-control"
                    popperClassName="datepicker-popper"
                    calendarClassName="datepicker-calendar"
                  />
                </div>
              </div>

              <div className="pickerDiv mb-20">
                <label className="white-text">{t("select_cashier")}</label>
                <select
                  value={selectedCashier}
                  onChange={(e) => setSelectedCashier(e.target.value)}
                  className="form-control"
                >
                  <option value="">{t("choose_cashier")}</option>
                  {members.map((cashier) => (
                    <option key={cashier.id} value={cashier.uid}>
                      {cashier.username}
                    </option>
                  ))}
                </select>
              </div>

              <button
                onClick={getOrdersData}
                className="themeBtn m-0 w-100 pos-submit-btn"
              >
                {t("search")}
              </button>
              {orderData?.length >= 250 && (
                <div className="alert">{t("more_than_250_orders")}</div>
              )}
            </div>
          </div>
        </div>
      </div>
      {loader && <Loader2 />}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {`${t("show_order")}: ${orderSelected?.id || "N/A"}`}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="orderDetails">
            {orderSelected?.id ? (
              <>
                <div className="mb-3 d-flex justify-content-between">
                  <label>{t("timestamp")}</label>
                  <div className="form-control-plaintext">
                    {orderSelected?.createdAt
                      ? moment(parseInt(orderSelected.createdAt)).format(
                          "DD MMMM YYYY HH:mm:ss"
                        )
                      : "N/A"}
                  </div>
                </div>
                <div className="mb-3 d-flex justify-content-between">
                  <label>{t("cashier")}</label>
                  <div className="form-control-plaintext">
                    {orderSelected.createdBy === "cashless"
                      ? "Fast-Pass"
                      : orderSelected.creatorName}
                  </div>
                </div>
                <div className="mb-3 d-flex justify-content-between">
                  <label>{t("customer")}</label>
                  <div className="form-control-plaintext">
                    {orderSelected.createdBy === "cashless"
                      ? orderSelected.creatorName
                      : null}
                  </div>
                </div>
                <div className="mb-3 d-flex justify-content-between">
                  <label>{t("payment_type")}</label>
                  <div className="form-control-plaintext">
                    {t(orderSelected.paymentType)}
                  </div>
                </div>
                <div className="mb-3 d-flex justify-content-between">
                  <div className="form-control-plaintext-left">{t("list")}</div>
                  <div className="form-control-plaintext">
                    {t("quantity_price")}
                  </div>
                </div>
                {orderSelected.products.map((product, index) => (
                  <div
                    key={index}
                    className="mb-3 d-flex justify-content-between px-3"
                    style={{
                      backgroundColor: "#025ef3",
                      borderRadius: "5px",
                      padding: "10px",
                    }}
                  >
                    <div className="form-control-plaintext-left">
                      {product.title}
                    </div>
                    <div className="form-control-plaintext">
                      {`${product.quantity} x ${product.price} €`}
                    </div>
                  </div>
                ))}
                <div className="mb-3 d-flex justify-content-between">
                  <div className="form-control-plaintext-left">
                    {t("total_amount")}
                  </div>
                  <div
                    className={
                      orderSelected.totalAmount >= 0
                        ? "form-control-plaintext-green"
                        : "form-control-plaintext-pink"
                    }
                  >
                    {orderSelected.totalAmount}€
                  </div>
                </div>
              </>
            ) : (
              <div className="text-center">{t("no_order_selected")}</div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

const Table = ({
  productData,
  orderData,
  totalRevenue,
  filter,
  setFilter,
  startDate,
  endDate,
  handleClickLiquidity,
}) => {
  const { t } = useTranslation("history");

  const handleExport = () => {
    exportToXlsx(orderData, startDate, endDate);
  };

  return (
    <div className="card themeCard themeTable br-10 border-transparent ">
      <div className="card-header bg-white border-transparent pt-3 d-flex justify-content-between align-items-center">
        <h6 className="m-0 tableHeading">{t("order_history")}</h6>
        <button
          className="themeBtn py-1 min-h-40 rounded-full m-0 text-capitalize min-w-131px"
          type="button"
          onClick={handleExport}
        >
          {t("export")}
        </button>
      </div>

      <div className="filterRow">
        <div
          className={filter === "All" ? "filBtn active" : "filBtn"}
          onClick={() => setFilter("All")}
        >
          <All />
          <span>{t("all")}</span>
        </div>
        <div
          className={filter === "Card" ? "filBtn active" : "filBtn"}
          onClick={() => setFilter("Card")}
        >
          <Card />
          <span>{t("card")}</span>
        </div>
        <div
          className={filter === "Cash" ? "filBtn active" : "filBtn"}
          onClick={() => setFilter("Cash")}
        >
          <Cash />
          <span>{t("cash")}</span>
        </div>
        <div
          className={filter === "Qr" ? "filBtn active" : "filBtn"}
          onClick={() => setFilter("Qr")}
        >
          <Qr />
          <span>{t("qr")}</span>
        </div>
        <div
          className={filter === "Refund" ? "filBtn active" : "filBtn"}
          onClick={() => setFilter("Refund")}
        >
          <Refund />
          <span>{t("refund")}</span>
        </div>
      </div>
      <div className="card-header br-10 table-responsive bg-white border-transparent py-3 d-flex flex-row align-items-center justify-content-between">
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">{t("type")}</th>
              <th scope="col">{t("timestamp")}</th>
              <th scope="col">{t("cashier")}</th>
              <th scope="col">{t("customer")}</th>
              <th scope="col">{t("amount")}</th>
            </tr>
          </thead>
          <tbody>
            {orderData.map((val) => {
              if (filter === "Card" && val?.paymentType === "card") {
                return (
                  <TableRow
                    key={val.id}
                    val={val}
                    handleClickLiquidity={(order) => {
                      handleClickLiquidity(order);
                    }}
                  />
                );
              } else if (filter === "Cash" && val?.paymentType === "cash") {
                return (
                  <TableRow
                    key={val.id}
                    val={val}
                    handleClickLiquidity={(order) => {
                      handleClickLiquidity(order);
                    }}
                  />
                );
              } else if (filter === "Qr" && val?.paymentType === "qr") {
                return (
                  <TableRow
                    key={val.id}
                    val={val}
                    handleClickLiquidity={(order) => {
                      handleClickLiquidity(order);
                    }}
                  />
                );
              } else if (filter === "Refund" && val?.totalAmount < 0) {
                return (
                  <TableRow
                    key={val.id}
                    val={val}
                    handleClickLiquidity={(order) => {
                      handleClickLiquidity(order);
                    }}
                  />
                );
              } else if (filter === "All") {
                return (
                  <TableRow
                    key={val.id}
                    val={val}
                    handleClickLiquidity={(order) => {
                      handleClickLiquidity(order);
                    }}
                  />
                );
              } else {
                return null; // Return null if the condition is not met
              }
            })}

            {orderData.length === 0 && (
              <tr>
                <td colSpan={5}>
                  <h4 className="text-center">{t("no_data")}</h4>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

function TableRow({ val, handleClickLiquidity }) {
  const { t, i18n } = useTranslation("history");
  // const findPDetail = () => {
  //   let findOrder = [];
  //   let findQantity = 0;
  //   let findPrice = 0;
  //   // console.log("orderData");
  //   // console.log(orderData);
  //   var order = orderData.forEach((element) => {
  //     let dd = element.products.find((p) => p.title === val.title);
  //     if (dd) {
  //       findOrder.push(dd);
  //       findQantity = findQantity + dd.quantity;
  //       findPrice = findPrice + dd.quantity * dd.price;
  //     }
  //   });
  //   setProdPrice(findPrice);
  //   setProdQuantity(findQantity);
  // };

  // useEffect(() => {
  //   findPDetail();
  // }, [orderData]);

  return (
    <tr
      onClick={() => {
        handleClickLiquidity(val);
      }}
    >
      <td scope="row">
        <div className="profileDiv">
          <div className="imgDiv">
            <img
              src={
                val?.paymentType === "card"
                  ? Icons.card
                  : val?.paymentType === "cash"
                  ? Icons.cash
                  : Icons.scan
              }
              alt=""
            />
          </div>
          <div className="name">{t(val?.paymentType)}</div>
        </div>
      </td>

      <td>
        {val?.createdAt
          ? moment(parseInt(val.createdAt)).format("DD MMM YY HH:mm:ss")
          : "N/A"}
      </td>

      <td>{val?.createdBy === "cashless" ? "Fast-Pass" : val?.creatorName}</td>
      <td>{val?.createdBy === "cashless" ? val?.creatorName : null}</td>
      <td className={val?.totalAmount >= 0 ? "price" : "refund"}>
        {val?.totalAmount.toFixed(2)}
      </td>
    </tr>
  );
}

function RevenueChart({
  softRevenue,
  beerRevenue,
  cocktailRevenue,
  hotDrinkRevenue,
  foodRevenue,
  wineRevenue,
  otherRevenue,
}) {
  const isBigScreen = useMediaQuery({ query: "(min-width: 576px)" });
  const [height, setHeight] = useState(170);
  const [width, setWidth] = useState(170);
  const [cx, setCx] = useState(80);
  const [cy, setCy] = useState(80);
  const [innerRadius, setInnerRadius] = useState(50);
  const [outerRadius, setOuterRadius] = useState(70);

  useEffect(() => {
    if (isBigScreen) {
      setHeight(170);
      setWidth(170);
      setCx(80);
      setCy(80);
      setInnerRadius(50);
      setOuterRadius(70);
    } else {
      setHeight(120);
      setWidth(120);
      setCx(53);
      setCy(53);
      setInnerRadius(40);
      setOuterRadius(53);
    }
  }, [isBigScreen]);

  const data = [
    { name: "Group A", value: beerRevenue || 10 },
    { name: "Group B", value: softRevenue || 10 },
    { name: "Group C", value: foodRevenue || 10 },
    { name: "Group D", value: cocktailRevenue || 10 },
    { name: "Group E", value: wineRevenue || 10 },
    { name: "Group F", value: hotDrinkRevenue || 10 },
    { name: "Group G", value: otherRevenue || 10 },
  ];
  const COLORS = [
    "#025ef3",
    "#F302CA",
    "#00af00",
    "#F2C94C",
    "#ffa500",
    "#ea0000",
    "#7600EF",
  ];

  return (
    // <ResponsiveContainer width="100%" height="100%">
    <PieChart width={width} height={height} onMouseEnter={""}>
      <Pie
        data={data}
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        fill="#8884d8"
        paddingAngle={5}
        dataKey="value"
      >
        {data.map((key, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
    //  </ResponsiveContainer>
  );
}

export { History };
